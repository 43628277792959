@import 'styles/style.scss';

.modal {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10001;

    background-color: $gray-60;
    backdrop-filter: blur(3px);

    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form {
    position: relative;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;

    background-color: $white;
    border-radius: 16px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: $shadow;
    animation: ani 0.3s forwards;
}

.cross {
    position: absolute;
    padding: 0;
    outline: 0;
    top: 36px;
    right: 36px;
    color: $gray-20;
    cursor: pointer;
    z-index: 100;
}

@keyframes ani {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 840px) {

    .wrapper {
        align-items: end;
        justify-content: stretch;
        width: 100%;
    }

    .modal {
        align-items: end;
    }

    .form {
        border-radius: 16px 16px 0 0;
        padding-top: 32px;
        width: 100%;
    }

    .cross {
        top: 24px;
        right: 24px;
    }

}