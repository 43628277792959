@import "styles/style";

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.button {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 8px;
    white-space: nowrap;

    border: 1px solid transparent;
    border-radius: 50px;

    font-family: $mainFont;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    outline: 0;

    transition: background-color 0.2s ease, box-shadow 0.2s ease;

    &_stretch {
        width: 100%;
    }

    &_icon-right {
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    &_dark {
        color: $white;
        background-color: $gray-10;

        &:hover {
            background-color: $gray-0;
        }

        &:active,
        :focus {
            border: 1px solid $primary-2;
        }
    }

    &_primary {
        color: $white;
        background-color: $primary-1;

        &:hover {
            background-color: $primary-2;
        }

        &:active,
        :focus {
            border: 1px solid $primary-2;
        }
    }

    &_light {
        color: $gray-10;
        background-color: $purple-95;

        &:hover {
            background-color: $purple-90;
        }

        &:active,
        :focus {
            border: 1px solid $primary-2;
        }
    }

    &_border {
        color: $gray-10;
        background-color: transparent;
        border: 1px solid $gray-20;

        &:hover {
            background-color: $purple-99;
        }

        &:active,
        :focus {
            border: 1px solid $primary-2;
        }
    }

    &_transparent {
        color: $gray-10;
        background-color: transparent;

        &:hover {
            background-color: $purple-99;
        }

        &:active,
        :focus {
            border: 1px solid $primary-2;
        }
    }

    &_white {
        color: $gray-10;
        background-color: #ffffff;

        &:hover {
            background-color: #ffffff;
        }

        &:active,
        :focus {
            border: 1px solid $gray-30;
        }
    }

    &_xs {
        height: 32px;
        line-height: 28px;
        font-size: 15px;
        padding: 0 12px;
        gap: 4px;

        .icon {
            width: 24px;
        }
    }

    &_xs_tall {
        height: 40px;
        line-height: 28px;
        font-size: 15px;
        padding: 0 12px;
        gap: 4px;
    }

    &_s {
        height: 40px;
        line-height: 36px;
        font-size: 15px;
        padding: 0 16px;

        .icon {
            width: 24px;
        }
    }

    &_m {
        height: 48px;
        line-height: 44px;
        font-size: 20px;
        padding: 0 20px;
    }

    &_l {
        height: 56px;
        line-height: 52px;
        font-size: 24px;
        padding: 0 24px;
    }

    // &:hover:not(&:disabled) {
    //     background-color: $black;
    //     color: $white;
    // }

    &:disabled {
        background-color: #18171B33;
        box-shadow: none;
        color: $white;
        cursor: not-allowed;
    }

}

.text {
    // padding-top: 4px;
    // display: inline-block;
    // vertical-align: middle;
}

@media screen and (max-width:$screen-mobile-mid) {}

@media screen and (max-width: 840px) {
    .button {
        &_xs {
            height: 32px;
            line-height: 28px;
            font-size: 13px;
            padding: 0 12px;
            gap: 4px;
    
            .icon {
                width: 24px;
            }
        }

        &_s {
            height: 40px;
            line-height: 36px;
            font-size: 15px;
            padding: 0 16px;
    
            .icon {
                width: 24px;
            }
        }

        &_m {
            height: 40px;
            font-size: 17px;
        }

        &_l {
            height: 48px;
            font-size: 20px;
        }
    }
}