@import 'styles/style.scss';

.wrapper {
    background-color: #EEEFF4;
    border-radius: 24px;
    padding: 60px 32px 32px 32px;
    min-width: 300px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    font-family: $mainFont;
    font-size: 28px;
    padding-bottom: 24px;
}

.button {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.button-anonymous {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.help-anonymous {
    padding-top: 16px;
    font-size: 15px;
    color: $gray-10;
}


@media screen and (max-width: 840px) {
    .wrapper {
        padding: 24px;
    }
}