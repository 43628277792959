@import 'styles/style.scss';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex: 6;
}

.mobile {
    position: relative;
    display: flex;
    width: 100%;
}

.mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo {
        width: 100%;
        background-color: $additional-6;
        padding-top: 80px;
    }

    &__img {
        // max-height: 550px;
        width: 100%;
    }

    &__content {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo {
        background-color: $additional-6;
    }

    .back {
        position: fixed;
        top: 0;
        padding: 24px 0 0 24px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: $additional-6;
    }
}

.logo {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(238, 239, 244, 1);
    flex: 4;

    object-fit: cover;

    &_pink {
        background-color: #FFE1E3;
    }
}

.img {
    object-fit: cover;
}


@media screen and (max-width: 1700px) {

    .mobile {

        &__drizzle {
            display: none;
        }
    }
}

@media screen and (max-width:$screen-web-min) {
    .logo {
        display: none;
    }
}