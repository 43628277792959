@import 'styles/style.scss';

.wrapper {
    // width: 384px;
    width: 100%;
    height: 100%;
    background-color: #EEEFF4;
    border-radius: 24px;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrapper-anonymous {
    width: 384px;
    height: 444px;
    background-color: #EEEFF4;
    border-radius: 50px;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description {
    padding-top: 12px;
    font-family: $mainFont;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    padding-bottom: 16px;
}

.button {
    display: flex;
    justify-content: flex-end;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.button-anonymous {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.help-anonymous {
    padding-top: 16px;
    font-size: 15px;
    color: $gray-10;
}

@media screen and (max-width: 840px) {

    .wrapper {
        padding: 24px;
    }

    .description {
        padding-top: 0;
        font-size: 20px;
        line-height: 28px;
        color: #18171BF5;
    }

    .help-anonymous {
        font-size: 13px;
    }

    .icon {
        display: flex;
        width: 40px;
    }
}