@import 'styles/style.scss';

.container {
    max-width: $screen-web-max;
}

.content {
    display: flex;
    gap: 16px;
}

.info {
    display: flex;
    gap: 120px;
    padding: 80px 0 112px 0;
}

.balance {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.welcome {
    display: flex;
}

@media screen and (max-width:$screen-web-mid) {
    .info {
        gap: 30px
    }
}

@media screen and (max-width:$screen-web-min) {
    .info {
        flex-wrap: wrap;
        justify-content: center;
    }

    .welcome {
        display: none;
    }
}