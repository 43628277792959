@import 'styles/style.scss';

.wrapper {
    display: flex;
    // line-clamp: 2;
    justify-content: center;
    gap: 12px;
    width: 100%;
}

@media screen and (max-width: 840px) {
    .wrapper {
        flex-wrap: wrap;
        gap: 8px;
    }
}