@import 'styles/style.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 24px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1440px;
    color: black;
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 16px
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons {
    display: flex;
    gap: 8px;
}

.logoWrapp {
    display: flex;
    align-items: center;
    gap: 12px;
}

@media screen and (max-width: 840px) {
    .container {
        padding: 0px 16px 0 16px;
    }

    .logoMenu {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .logo {
        width: 80px;
    }
}