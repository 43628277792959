@import 'styles/style.scss';

.container {
    width: 100%;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    // height: 96px;
}

.imgWrapp {
    position: relative;
}

.img {
    width: 96px;
    height: 96px;
    border-radius: 12px;
    // border: 1px solid $gray-10;
}

.info {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    gap: 16px;
    font-family: $mainFont;
    color: $gray-10;
    width: 100%;

    &__name {
        font-size: 23px;
        line-height: 24px;
        height: 24px;
    }

    &__analytic {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 12px;
        gap: 16px;
        font-size: 17px;
        line-height: 20px;
    }
}

.networks {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    gap: 16px;

    &_disabled {
        color: $gray-90;
    }
}

.network {
    cursor: pointer;
}

.description {
    overflow: hidden;
    color: $gray-20;
    font-size: 15px;
    width: 100%;
}

.star {
    position: absolute;
    right: -20px;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
}


.position {
    position: absolute;
    font-family: $mainFont;
    font-size: 17px;
}