@import 'styles/style.scss';

.container {
    max-width: 1440px;
    padding: 0 32px;
    box-sizing: border-box;
}

.content {
    display: flex;
    gap: 16px;
}

.info {
    display: flex;
    gap: 44px;
    padding: 80px 0;
}

.balance {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.welcome {
    display: flex;
}

.mobile {
    padding: 8px;
    padding-top: 94px;
    box-sizing: border-box;
    width: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        gap: 8px;

        width: 100%;
    }
}

@media screen and (max-width:$screen-web-mid) {
    .info {
        gap: 30px
    }
}

@media screen and (max-width:$screen-web-min) {
    .info {
        flex-wrap: wrap;
        justify-content: center;
    }
}