@import 'styles/style.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 320px;
    padding: 32px;
    box-sizing: border-box;
    background-color: rgba(239, 232, 226, 1);
    border-radius: 24px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.balance {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 12px;
    font-family: $mainFont;
    width: 100%;

    &__label {
        font-size: 28px;
        color: $gray-0;
    }

    &__amount {
        font-size: 40px;
        color: $gray-0;
        line-height: 44px;
        font-weight: 200;
    }
}

.error {
    display: flex;

    background-color: #FF000014;
    padding: 16px;
    gap: 8px;
    border-radius: 8px;

    &__text {
        font-family: $mainFont;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0em;
    }
}

.info {
    font-family: $mainFont;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0em;
}

.coinWrapp {
    display: flex;
    align-items: center;
    gap: 8px;
}

@media screen and (max-width: 840px) {
    .wrapper {
        min-height: 100%;
        padding: 24px;
    }

    .balance {
        align-items: center;

        &__label {
            font-size: 23px;
        }

        &__amount {
            font-size: 32px;
            line-height: 36px;
        }
    }
}