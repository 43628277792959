body {
  margin: 0;
  font-family: 'PPTelegraf', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'PPTelegraf';
  /* font-weight: 400; */
  src: local('PPTelegraf'), url(./assets/fonts/Telegraf_Regular/PPTelegraf-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'PPTelegraf Semibold';
  /* font-weight: 600; */
  src: local('PPTelegraf Semibold'), url(./assets/fonts/Telegraf_Semibold/PPTelegraf-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'PPTelegraf Ultrabold';
  /* font-weight: 600; */
  src: local('PPTelegraf Ultrabold'), url(./assets/fonts/Telegraf_Ultrabold/PPTelegraf-Ultrabold.otf) format('opentype');
}