@import '../styles/style.scss';

.wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
}

.title {
    font-family: $mainFont;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
}

.description {
    font-family: $mainFont;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

@media screen and (max-width: 840px) {
    .wrapper {
        gap: 16px;
    }

    .title {
        font-size: 18px;
        line-height: 18px;
        font-weight: 800;
    }

    .description {
        font-size: 18px;
        line-height: 18px;
    }

    .svg {
        display: flex;
        width: 70px;
    }
}