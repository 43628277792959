@import 'styles/style.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 692px;
    gap: 32px;
}

.title {
    font-family: $mainFont;
    font-size: 34px;
    font-weight: 400;
    line-height: 62px;
    color: #18171BC2;
}

.text {
    font-size: 34px;
    max-width: 572px;
    color: #18171BC2;
}

.img {
    max-width: 445px;
}

@media screen and (max-width: 840px) {
    .wrapper {
        flex-direction: column-reverse;
        padding: 0 12px;
        padding-bottom: 24px;
    }

    .title {
        font-size: 27px;
        line-height: 32px;
    }

    .text {
        font-size: 27px;
        line-height: 32px;
        max-width: none;
        width: 100%;
    }

    .img {
        max-width: 312px;
    }
}