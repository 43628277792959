@import 'styles/style.scss';

.wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;

    box-sizing: border-box;
    border-radius: 16px;

    width: 100%;
    width: 452px;
    height: 100%;
}

.profile {
    display: flex;
    gap: 16px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.title {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 32px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0px;
}

.description {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.01em;
}

.buttons {
    display: flex;
    gap: 8px;
}

.label {
    display: block;
    font-family: $mainFont;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 8px;
}


.colors {
    display: flex;
    gap: 8px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 48px;
        height: 48px;
        border: 1px solid $gray-20;
        border-radius: 16px;
        cursor: pointer;

        &_pink {
            background: $additional-4;
        }
    }

}

.chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.links {
    padding-bottom: 22px;
}


@media screen and (max-width: 840px) {
    .label {
        font-size: 15px;
        padding-bottom: 4px;
    }

    .wrapper {
        gap: 16px;
    }

    .description {
        font-size: 13px;
        line-height: 16px;
        max-width: 400px;
    }

    .colors {
        &__item {
            border-radius: 8px;
            width: 36px;
            height: 36px;
        }
    }

    .title {
        font-size: 40px;
        line-height:44px;
        font-weight: 400;
    }
}

@media screen and (max-width: 530px) {

    // .title {
    //     font-size: 40px;
        
    // }

    .wrapper {
        width: 100%;
        max-width: 360px;
    }
}