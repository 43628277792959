@import 'styles/style.scss';

.wrapper {
    width: 100%;
    max-width: 432px;
    color: $gray-10;
    padding: 24px 0;
}

.title {
    font-size: 52px;
    line-height: 56px;
    font-family: $mainFont;
    padding: 16px 0;
}

.description {
    font-size: 20px;
    line-height: 24px;
    font-family: $mainFont;
    padding-bottom: 48px;
}

.help {
    font-size: 17px;
    line-height: 24px;
    font-family: $mainFont;

    &__signin {
        color: $primary-1;
    }
}

.button {
    padding-top: 40px;
}