@import 'styles/style.scss';

.wrapper {
    // max-width: 348px;
    width: 100%;
    height: 100%;
    background-color: $surface-3;
    border-radius: 24px;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description {
    padding-top: 12px;
    font-family: $mainFont;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
}

.list {
    list-style-type: decimal;
    margin: 0;
    padding: 0;
    padding: 16px;



    li {
        padding-bottom: 16px;
        color: $gray-20;
        font-family: $mainFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-left: 0;
    }
}

.link {
    text-decoration: solid;
}

@media screen and (max-width: 840px) {

    .icon {
        display: flex;
        width: 40px;
    }

    .wrapper {
        padding: 24px;
    }

    .list {
        padding: 16px 0 16px 16px;

        li {
            font-size: 13px;
            line-height: 16px;
        }
    }

    .description {
        padding-top: 0;
        font-size: 20px;
        line-height: 28px;
    }
}