@import 'styles/style.scss';

.step {
    font-family: $mainFont;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
}

.back {
    display: flex;
    align-items: center;
    gap: 4px;

    font-family: $mainFont;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    cursor: pointer;
    padding-top: 12px;
}