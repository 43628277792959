@import 'styles/style.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 480px;
    padding: 56px;
    box-sizing: border-box;
    width: 100%;
    gap: 24px;
    animation: ani 1s forwards;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
}

.link {
    color: #3300FF;
    text-decoration: none;
}

.description {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
}

.button {
    padding-top: 24px;
    display: flex;
    gap: 8px;
}

.saving {
    max-width: 488px;
}

@media screen and (max-width: 840px) {

    .title {
        font-size: 27px;
        line-height: 32px;
    }


    .saving {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 320px;
        // border: 1px solid black;
        margin-top: 24px;
    }

    .wrapper {
        max-width: 100%;
        padding: 32px 24px;
        box-sizing: border-box;
    }
}