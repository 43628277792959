@import 'styles/style.scss';

.wrapper {
    width: 100%;
}

.tags {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-bottom: 8px;
}

.button {
    padding-top: 16px;
}


.tag {
    border-radius: 8px;
    padding: 12px;
    border: 1px solid $gray-20;
    box-sizing: border-box;
    width: 100%;

    font-family: $mainFont;
    color: $gray-10;
    line-height: 24px;
    font-size: 17px;
    height: 48px;
    background-color: transparent;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        border: 1px solid $primary-1;
    }

    &:focus {
        border: 1px solid $primary-1;
    }

    &_active {
        background-color: $purple-95;
    }
}

@media screen and (max-width: 840px) {

    .tag {
        font-size: 15px;
        padding: 8px 12px;
        height: 40px;
    }
}