@import 'styles/style.scss';

.container {
    max-width: $screen-web-mid;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 32px;
    box-sizing: border-box;
}

.content {
    display: flex;
    gap: 16px;
    width: 100%;
}

.info {
    // width: 100%;
    display: flex;
    width: 100%;
    gap: 44px;
    padding: 80px 0;
}

.balance {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}

.welcome {
    display: flex;
}

.mobile {
    padding: 8px;
    padding-top: 94px;
    box-sizing: border-box;
    width: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        gap: 8px;

        width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .info {
        flex-direction: column;
    }

    .welcome {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

@media screen and (max-width:$screen-web-mid) {
    // .welcome {
    //     // display: none;
    // }
}