@import "styles/style";

.wrapper {
    position: relative;
    width: 100%;
}

.input {
    position: relative;
    border-radius: 8px;
    outline: 0;
    border: 1px solid $gray-20;
    padding: 16px 50px 16px 12px;
    box-sizing: border-box;
    width: 100%;
    font-family: $mainFont;
    color: $gray-10;
    line-height: 24px;
    background-color: transparent;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none; // Для Chrome и Safari
      margin: 0;
    }

    &_m {
        height: 48px;
        font-size: 17px;
    }

    &_l {
        height: 56px;
        font-size: 20px;
    }

    &:hover {
        border: 1px solid $primary-1;
    }

    &:focus {
        border: 1px solid $primary-1;
    }

    &::placeholder {
        font-family: $gray-60;
    }
}

.label {
    display: block;
    font-family: $mainFont;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 8px;
}

.container {
    position: relative;
}

.icon {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 7px 10px;
    cursor: pointer;
}

.error {
    font-family: $mainFont;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0em;
    color: #FF0000;
    padding-top: 4px;
}

@media screen and (max-width: 840px) {
    .input {
        font-size: 15px;

        &_m {
            height: 40px;
            font-size: 15px;
        }
    }

    .label {
        font-size: 15px;
        padding-bottom: 4px;
    }

    .icon {
        top: 1px;
        right: 1px;
    }
}