@import '../styles/style.scss';

.wrapper {
    width: 100%;
    height: 100%;
}

.layout {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    z-index: 80;
}