@import '../../styles/style.scss';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 32px;

    box-sizing: border-box;
    border-radius: 16px;
    flex-grow: 1;

    width: 100%;
    max-width: 536px;
    z-index: 11;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    color: $gray-10;
    font-family: $mainFont;
    padding-top: 24px;

    font-size: 44px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0px;
    text-align: center;

}

.description {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    padding-bottom: 32px;
    text-align: center;

}

.drizzle_wrapp {
    position: relative;
}

.drizzle {
    position: absolute;
    width: 300px;
    color: $drizzle-color;
    top: -90px;
    left: -200px;
    z-index: 0,
}

.withdraw {
    background-color: white;
    width: 100%;
    max-width: 536px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    background: white;
    flex-grow: 1;
}

.inside-wrapper {
    width: 100%;
    max-width: 410px;
    gap: 8px;
    padding: 0 24px;
    box-sizing: border-box;
}

.tags {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding-bottom: 8px;
}

.tag {
    border-radius: 8px;
    padding: 12px;
    border: 1px solid $gray-20;
    box-sizing: border-box;
    width: 100%;

    font-family: $mainFont;
    color: $gray-10;
    line-height: 24px;
    font-size: 17px;
    height: 48px;
    background-color: transparent;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        border: 1px solid $primary-1;
    }

    &:focus {
        border: 1px solid $primary-1;
    }

    &_active {
        background-color: $purple-95;
    }
}



@media screen and (max-width: 840px) {

    .container {
        justify-content: flex-start;
    }

    .wrapper {
        padding: 0 16px;
    }

    .withdraw {
        border-radius: 32px 32px 0 0;
    }

    .title {
        font-size: 26px;
        line-height: 32px;
    }

    .description {
        font-size: 15px;
        line-height: 20px;
    }

    .drizzle_wrapp {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .drizzle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        left: 0px;
        padding: 0 24px;
        box-sizing: border-box;
    }

    .bg {
        border-radius: 32px 32px 0 0;
    }
}

@media screen and (max-height: 850px) {
    .container {
        padding-top: 20%;
    }
}

@media screen and (max-height: 710px) {
    .container {
        padding-top: 120px;
    }
}

@media screen and (max-height: 630px) {
    .container {
        padding-top: 150px;
    }
}