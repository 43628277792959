@import 'styles/style.scss';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 432px;
    box-sizing: border-box;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    flex: 6;
}

.logo {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFF;

    flex: 4;

    &_yellow {
        @extend .logo;
        background: #FFF5EC;
    }

    &_gray {
        @extend .logo;
        background: #EFE8E2;
    }

    &_blue {
        @extend .logo;
        background: #E8EAF5;
    }

    &_green {
        @extend .logo;
        background: #DCEDDD;
    }
}

.title {
    font-family: $mainFont;
    font-size: 44px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0px;
    margin: 0;
}

.button-title {
    font-family: $mainFont;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
}

.item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-bottom: 12px;

    font-family: $mainFont;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;

    span {
        max-width: 360px;
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media screen and (max-width:$screen-web-min) {
    .logo {
        display: none;
    }

    .form {
        padding: 0 32px;
    }
}

@media screen and (max-width: 840px) {
    .title {
        font-size: 28px;
        line-height: 34px;

    }

    .item {
        font-size: 16px;
        line-height: 18px;
    }
}

@media screen and (max-width: 500px) {
    .title {
        padding-top: 44px;
    }
}