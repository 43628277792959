@import 'styles/style.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // width: 348px;
    height: 100%;
    padding: 32px;
    box-sizing: border-box;
    background-color: #DAEAD9;
    border-radius: 24px;
    gap: 12px;
}

.balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    font-family: $mainFont;

    &__label {
        font-family: $mainFont;
        color: $gray-10;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
    }

    &__amount {
        font-size: 40px;
        color: $gray-10;
        line-height: 44px;
        font-weight: 200;
    }
}

.button {
    display: flex;
    flex-direction: column;
    gap: 12px;
}