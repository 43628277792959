@import 'styles/style.scss';

.modal {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;

    background: var(--On-Light-60, #18171B7A);


    box-sizing: border-box;

    &__navbar {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0 24px;
        width: 100%;
        box-sizing: border-box;
        height: 72px;
        z-index: 1001;
    }
}

.wrapper {
    box-sizing: border-box;
    background-color: white;
    height: 100vh;
    width: 304px;
}

.form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $white;
    border-radius: 16px;
    width: 100%;
    box-sizing: border-box;

    animation: ani 0.3s forwards;
}

.cross {
    color: $gray-60;
    cursor: pointer;
}

.header {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 20px;
    box-sizing: border-box;
}

.logoWrapp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.content {
    display: flex;
}

.logo {
    display: flex;
    width: 80px;
}

.svg {
    display: flex;
    align-items: center;
    width: 16px;
}

.menuList {
    border-bottom: 1px solid #18171B1F;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px 0;


    &__item {
        height: 48px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        cursor: pointer;

        &_active {
            background: #5236BE1F;

        }
    }
}

@keyframes ani {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.menu {
    width: 100%;

    &__account {
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: $mainFont;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0px;
        overflow: hidden;

        &__container {
            display: flex;
            gap: 16px;
        }
    }

    &__header {
        display: flex;
        gap: 8px;
        padding: 16px;
        border-bottom: 1px solid $gray-95;
    }

    &__item {
        padding: 16px 24px;
        display: flex;
        gap: 16px;

        font-family: $mainFont;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0px;

        cursor: pointer;

        &:hover {
            background-color: $purple-99;
        }

    }

    &__logout {
        border-top: 1px solid $gray-95;
    }


}

.content {
    display: flex;
    flex-direction: column;
}

.name {
    font-family: $mainFont;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
}

.mail {
    font-family: $mainFont;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0em;
}

.delete {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    border-radius: 50px;

    cursor: pointer;

    &:hover {
        background-color: $purple-99;
    }
}

.img {
    width: 40px;
    height: 40px;
}