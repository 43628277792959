@import 'styles/style.scss';

.preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 6;
    z-index: 1;

    border-left: 1px solid $gray-90;

    width: 100%;
    height: 100%;

    &__logo {
        position: absolute;
        left: 24px;
        top: 24px;
        display: flex;
        align-items: center;
        gap: 16px;
        z-index: 1;

        font-family: $mainFont;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0;
    }

    &__logo__full {
        position: absolute;
        left: 24px;
        top: 84px;
        display: flex;
        align-items: center;
        gap: 16px;
        z-index: 1;

        font-family: $mainFont;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0;
    }
}