.ethAddressWrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* Optional: adds a dotted underline */
}

.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px 3px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 80%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.page_link {
    border-bottom: 1px dotted #5c5c5c;
    text-decoration: none;
    color: #5c5c5c;
    display: inline-flex;
    line-height: 20px;
}