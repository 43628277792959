@import 'styles/style.scss';

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // align-items: flex-end;
    justify-content: flex-end;
    gap: 8px;
    height: 140px;
    width: 100%;
    min-width: 242px;
    border: 1px solid $gray-60;
    border-radius: 24px;
    padding: 32px;
    box-sizing: border-box;
    color: #18171BF5;
}

.number {
    width: 100%;
    font-family: $mainFont;
    font-size: 40px;
    color: $gray-10;
    line-height: 44px;
    font-weight: 200;
    white-space: nowrap;
}

.description {
    font-size: 17px;
    line-height: 24px;
    color: $gray-10;
    font-family: $mainFont;
    font-weight: 200;

}

.tooltip {
    width: 100%;
    color: $gray-60;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
}

.tooltip-custom {
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
    padding: 20px;
}

@media screen and (max-width: 1440px) {
    .wrapper {
        padding: 16px;

    }

    .number {
        font-size: 36px;
    }

    .description {
        font-size: 14px;
    }
}

@media screen and (max-width: 1170px) {
    .wrapper {
        min-width: 176px;
    }

    .number {
        // font-size: 28px;
        font-size: 32px;
    }

    .description {
        font-size: 12px;
        line-height: 14px;
    }

}

@media screen and (max-width: 840px) {
    .wrapper {
        // padding: 16px;
    }
}

@media screen and (max-width: 500px) {
    .wrapper {
        max-width: none;
        height: 100px;
        padding: 16px 16px;
        gap: 0;
    }

    .number {
        font-size: 32px;
    }

    .description {
        font-size: 17px;
        line-height: 14px;
    }
}