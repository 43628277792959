@import 'styles/style.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    padding: 56px;
    box-sizing: border-box;
    width: 100%;
    gap: 24px;
    animation: ani 1s forwards;
}

.title {
    font-family: $mainFont;
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
}

.button {
    display: flex;
    gap: 8px;
    padding-top: 24px;
}

.saving {
    max-width: 488px;
}

@media screen and (max-width: 840px) {


    .wrapper {
        max-width: 100%;
        padding: 32px 24px;
    }
}

@media screen and (max-width: 400px) {

    .saving {
        display: none;
    }

    .wrapper {
        max-width: 100%;
        padding: 32px 24px;
        gap: 12px;
    }

    .title {
        padding-top: 44px;
        font-size: 27px;
        line-height: 32px;
    }
}