@import 'styles/style.scss';

.modalPopup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.wrapperPopup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 176px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 368px;

    width: 100%;
    gap: 16px;
    animation: ani 1s forwards;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.title {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 52px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0px;
}

.description {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 12px;
}

.button {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.content {
    position: relative;
    margin-top: 64px;

    display: flex;
    flex-direction: column;
    max-width: 414px;
    border-radius: 16px;
    box-sizing: border-box;

    border: 1px solid $gray-90;
    box-shadow: 0px 12px 20px -8px #00000040;

    object-fit: cover;

    &__picture {
        position: relative;
        display: flex;
        gap: 16px;
    }

}

.info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 40px;
    box-sizing: border-box;

    &__title {
        font-family: $mainFont;
        font-size: 34px;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0px;
    }

    &__description {
        font-family: $mainFont;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
    }
}

.img {
    width: 414px;
    height: 348px;
    border-radius: 16px 16px 0 0;
    object-fit: cover;
}

.extra {
    position: absolute;
    left: 0;
    top: -66px;
    color: #492CBD;
    z-index: 10;

    &__text {
        max-width: 220px;
        font-family: $mainFont;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;

    }

    &__icon {
        position: absolute;
        right: -52px;
        top: 4px;
    }
}

.crossWrapp {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 12;
    padding-top: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.cross {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 950px;
}

@media screen and (max-width: 1050px) {
    .wrapper {
        gap: 32px
    }
}

@media screen and (max-width: 840px) {
    .wrapperPopup {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 76px;
        overflow-y: auto;
        height: 100vh;
        width: 100%;
        padding: 0 24px;
        padding-top: 55px;
    }

    .title {
        font-size: 32px;
        line-height: 32px;
    }

    .form {
        flex-direction: column;
        height: 800px;
        gap: 12px;
        width: 100%;
        max-width: 370px;
        z-index: 10;
    }

    .content {
        max-width: 320px;
        margin-top: 32px;
        margin-bottom: 124px;
    }

    .info {
        &__title {

            font-size: 22px;
            line-height: 26px;
            font-weight: 600;
        }
    }

    .img {
        width: 320px;
        height: 210px;
    }

    .modalWrapp {
        background-color: transparent;
    }

    .crossWrapp {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 18px;
        padding-top: 0px;
    }

    .cross {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 15px;
    }

    .modalPopup {
        height: 100%;
    }
}