@import 'styles/style.scss';

.container {
    position: fixed;
    top: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 72px;
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 1px solid $gray-80;
    background-color: $white;
    z-index: 102;

    &_active {
        background-color: #B4C0FF;
    }
}

.content {
    display: flex;
    align-items: center;
    gap: 24px;
}

.content_extra {
    display: flex;
    align-items: center;
    gap: 24px;
}

.cross {
    cursor: pointer;
}

.title {
    font-family: $mainFont;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
}

.info {
    font-family: $mainFont;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
}

.header {
    display: flex;
    align-items: center;
    gap: 24px;
}

@media screen and (max-width: 840px) {

    .container {
        padding: 24px 24px 0 24px;
        border-bottom: none;
        height: 30px;

        &_active {
            background-color: white;
        }
    }

    .content {
        width: 100%;
        gap: 12px;
        display: flex;
        justify-content: space-between;
    }

    .title {
        display: none;
    }

    .cross {
        display: flex;
        // padding-left: 24px;
        width: 15px;
    }

    .header {
        gap: 12px;
    }

    .info {
        display: none;
    }

    .content_extra {
        gap: 12px;
    }
}