@import 'styles/style.scss';

.wrapper {
    width: 100%;
    max-width: 1050px;
}

@media screen and (max-width: 1170px) {
    .wrapper {
        max-width: 1000px;
    }
}

@media screen and (max-width: 840px) {
    .wrapper {
    }
}

@media screen and (max-width: 520px) {
    .wrapper {
    }
}