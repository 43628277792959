@import 'styles/style.scss';

.wrapper {
    display: flex;
    align-items: center;
    height: 52px;
    gap: 16px;
    border-bottom: 1px solid $gray-95;
    width: 100%;
    color: $gray-60;
    font-size: 15px;
    line-height: 20px;
    font-family: $mainFont;
    box-sizing: border-box;
    text-align: start;
    padding: 16px 0;

    &:last-child {
        border-bottom: none;
    }
}

.item {
    padding-right: 4px;
    color: $gray-10;
    box-sizing: border-box;
    white-space: nowrap;
    // border: 1px solid green;
}

.status {
    padding: 4px 8px;
    text-align: center;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 800;
    width: fit-content;

    &_green {
        background: #A6DAA8;
    }

    &_blue {
        background: #A3CAE7;
    }

    &_orange {
        background: #FFCEB2;
    }

    &_red {
        color: $white;
        background: #FF0000;
    }

    &_gray {
        background: rgba(24, 23, 27, 0.08);
    }
}

.btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

// .tooltip {
//     width: 100%;
//     color: $gray-60;
//     position: absolute;
//     right: 16px;
//     top: 16px;
//     width: 24px;
//     height: 24px;
// }

@media screen and (max-width: 1170px) {
    .status {
        // padding: 4px;
        // font-size: 11px;
        // font-weight: 400;
        // min-width: 90px;
        width: fit-content;

    }

    .wrapper {
        cursor: pointer;
        gap: 8px;

        &:hover {
            background-color: $purple-95;
        }
    }
}