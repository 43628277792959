@import "styles/style";

.attention {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    gap: 8px;

    font-family: $mainFont;
    font-style: normal;
    font-weight: 400;

    &_red {
        color: #FF0000;
    }

    &_blue {
        color: #4428B0;
    }
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media screen and (max-width: 840px) {
    .text {
        font-size: 13px;
        line-height: 16px;
    }
}