@import 'styles/style.scss';

.modal {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    background-color: white;
    box-sizing: border-box;

    // display: flex;
    // align-items: center;
    // justify-content: center;

    &__navbar {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        // align-items: center;
        justify-content: space-between;

        padding: 0 24px;
        width: 100%;
        box-sizing: border-box;
        height: 60px;
    }
}

.wrapper {
    box-sizing: border-box;
}

.form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: $white;
    border-radius: 16px;
    width: 100%;
    box-sizing: border-box;

    animation: ani 0.3s forwards;
}

.cross {
    color: $gray-60;
    cursor: pointer;
}

@keyframes ani {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 840px) {
    .modal {
        &__navbar {
            background-color: white;
            padding: 0 24px;
            height: 60px;
            align-items: flex-end;
            padding-bottom: 16px;
            box-sizing: border-box;
            // border: 1px solid black;
            z-index: 2;
        }
    }

    .cross {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
    }
}