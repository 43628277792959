@import '../../styles/style.scss';

.container {
    max-width: $screen-web-max;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 432px;
    box-sizing: border-box;
}

.title {
    font-family: $mainFont;
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0px;
    margin: 0;
}


.item {
    padding-bottom: 12px;

    font-family: $mainFont;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;

    span {
        max-width: 360px;
    }
}