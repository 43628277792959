@import "styles/style";

.wrapper {
    position: relative;
    width: 100%;
}

.input {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    border-radius: 8px;
    outline: 0;
    height: 56px;
    border: 1px solid $gray-20;
    padding: 8px;
    box-sizing: border-box;
    width: 100%;
    font-family: $mainFont;
    color: $gray-10;
    line-height: 24px;
    background-color: transparent;
    background-position: center;
    background-size: cover;

    &_gray {
        background-image: url('../../assets/cover_gray.png');
        background-position-y: -220px;
    }

    &_yellow {
        background-image: url('../../assets/cover_yellow.png');
        background-position-y: -240px;
    }

    &_blue {
        background-image: url('../../assets/cover_blue.png');
        background-position-y: -220px;
    }

    &_green {
        background-image: url('../../assets/cover_green.png');
        background-position-y: -220px;
    }

    &:hover {
        border: 1px solid $primary-1;
    }

    &:focus {
        border: 1px solid $primary-1;
    }

}

.content {
    position: absolute;
    box-sizing: border-box;
    border: 1px solid $gray-10;
    border-radius: 8px;
    padding: 8px;
    top: 88px;
    width: 100%;
    z-index: 10000;

    background-color: $white;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.img {
    width: 171px;
    height: 115px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    object-fit: cover;

    &:hover {
        border: 1px solid $gray-60;
        box-sizing: border-box;
    }
}

.label {
    font-family: $mainFont;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 8px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;

    color: $white;
    background-color: $gray-10;
    border: 1px solid $gray-10;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    &_active {
        color: $gray-10;
        background-color: $white;
    }
}


@media screen and (max-width: 840px) {
    .input {
        height: 40px;
    }

    .icon {
        width: 28px;
        height: 28px;
    }

    .content {
        top: 70px;
        // border: 1px solid gold;
        // flex-wrap: wrap;
    }

    .label {
        font-size: 15px;
        padding-bottom: 4px;
    }

    .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100px;
        object-fit:cover;
    }

    .imgWrapper {
        width: 100%;
    }
}

// @media screen and (max-width: 470px) {
//     .img {
//         width: 160px;
//     }
// }

// @media screen and (max-width: 400px) {
//     .img {
//         width: 160px;
//     }
// }

// @media screen and (max-width: 375px) {
//     .img {
//         width: 150px;
//     }
// }

// @media screen and (max-width: 330px) {
//     .img {
//         width: 125px;
//     }
// }