@import "styles/style";

.wrapper {
    position: relative;
    width: 100%;
}

.input {
    border-radius: 8px;
    outline: 0;
    border: 1px solid $gray-20;
    padding: 8px 12px;
    box-sizing: border-box;
    width: 100%;
    font-family: $mainFont;
    color: $gray-10;
    line-height: 24px;
    font-size: 17px;
    background-color: transparent;
    resize: none;

    &:hover {
        border: 1px solid $primary-1;
    }

    &:focus {
        border: 1px solid $primary-1;
    }

    &::placeholder {
        font-family: $gray-60;
    }
}

.label {
    display: flex;
    justify-content: space-between;
    font-family: $mainFont;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 8px;
}

.counter {
    font-family: $mainFont;
    text-align: right;

    &_error {
        color: #FF0000;
    }

}

.error {
    font-family: $mainFont;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0em;
    color: #FF0000;
    padding-top: 4px;
}

@media screen and (max-width: 840px) {
    .label {
        font-size: 15px;
        padding-bottom: 4px;
    }

    .counter {
        font-size: 15px;
    }

    .input {
        font-size: 15px;
    }
}