@import 'styles/style.scss';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding-top: 72px;
}

.edit-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 4;
    z-index: 2;
    background: $white;
    width: 100%;
    padding: 64px 0;
}

.scroll {
    padding: 0 32px;
    max-height: 80vh;
    overflow-y: auto;
}

.preview {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 6;
    z-index: 1;

    border-left: 1px solid $gray-90;

    width: 100%;
    height: 100%;

    &__logo {
        position: absolute;
        left: 24px;
        top: 24px;
        display: flex;
        align-items: center;
        gap: 16px;
        z-index: 1;

        font-family: $mainFont;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0em;
    }
}

@media screen and (max-width:$screen-web-min) {
    .preview {
        display: none;
    }
}

@media screen and (max-width: 840px) {
    .wrapper {
        border: none;
        padding-top: 54px;
        align-items: flex-start;
        padding-bottom: 20px;
    }

    .edit-content {
        padding: 0 0 0 0;
        flex: 1;
        // height: 100%;
        align-items: flex-start;
    }

    .scroll {
        height: 90vh;
        // max-height: 95vh;
        padding: 0 24px;
        overflow-y: auto;
    }
}