@import 'styles/style.scss';

.wrapper {
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    padding: 0 24px;
    width: 100%;
    max-width: $screen-web-max;
    box-sizing: border-box;
    z-index: 10;
}

@media screen and (max-width: 840px) {
    .wrapper {
        height: none;
    }
}