@import 'styles/style.scss';

.wrapper {
    width: 100%;
    max-width: 432px;
    color: $gray-10;
    padding: 0 24px;
    box-sizing: border-box;
}

.title {
    font-size: 52px;
    line-height: 56px;
    font-family: $mainFont;
    padding: 16px 0;
}

.description {
    font-size: 20px;
    line-height: 24px;
    font-family: $mainFont;
    padding-bottom: 48px;
}

.help {
    font-size: 17px;
    line-height: 24px;
    font-family: $mainFont;

    &__signin {
        color: $primary-1;
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
}

@media screen and (max-width: 840px) {
    .wrapper {
        padding: 0 0;
    }

    .title {
        font-size: 36px;
        line-height: 44px;
        padding: 16px 0 8px 0;
    }

    .description {
        font-size: 17px;
        line-height: 20px;
        padding-bottom: 32px;
    }

    .buttons {
        gap: 12px;
        padding-bottom: 16px;
    }

    .help {
        font-size: 15px;
        line-height: 20px;
    }
}