@import "styles/style";

.wrapper {
  position: relative;
  width: 100%;
}

.linkPrefix {
  position: absolute;
  height: 48px;
  display: flex;
  align-items: center;
  left: 12px;
  margin: auto;
}

.input {
  display: flex;
  font-size: 17px;
  width: 100%;
  position: relative;
  border-radius: 8px;
  outline: 0;
  border: 1px solid $gray-20;
  padding: 16px 24px 16px 122px;
  box-sizing: border-box;
  height: 48px;
  font-family: $mainFont;
  color: $gray-10;
  line-height: 24px;
  background-color: transparent;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; // Для Chrome и Safari
    margin: 0;
  }

  &_m {
    height: 48px;
    font-size: 17px;
  }

  &_l {
    height: 56px;
    font-size: 20px;
  }

  &:hover {
    border: 1px solid $primary-1;
  }

  &:focus {
    border: 1px solid $primary-1;
  }

  &::placeholder {
    font-family: $gray-60;
  }
}

.label {
  display: block;
  font-family: $mainFont;
  font-size: 17px;
  line-height: 24px;
  padding-bottom: 8px;
}

.container {
  border: 1px solid gold;
  position: relative;
  display: flex;
}

.icon {
  position: absolute;
  top: 12px;
  right: 6px;
  cursor: pointer;
  background-color: white;
  width: 22px;
  height: 32px;
  padding-left: 4px;
}

.error {
  font-family: $mainFont;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  color: #FF0000;
  padding-top: 4px;
}

@media screen and (max-width: 840px) {
  .linkPrefix {
    height: 40px;
  }

  .input {
    font-size: 15px;
    height: 40px;
    padding: 16px 0px 16px 112px;
  }

  .label {
    font-size: 15px;
    padding-bottom: 4px;
  }

  .linkPrefix {
    font-size: 14px;
    width: 115px;
  }

  .icon {
    top: 1px;
    right: 1px;
  }
}