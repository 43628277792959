@import '../../styles/style.scss';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 80px;
}