@import 'styles/style.scss';

.wrapper {
    width: 100%;
}

.content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid $gray-60;
    border-radius: 24px;
    padding: 24px;
    box-sizing: border-box;
    margin-top: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 8px;
}

.title {
    font-family: $mainFont;
    font-size: 40px;
    color: #18171BF5;
    line-height: 44px;
    font-weight: 200;
}

@media screen and (max-width: 1440px) {

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .container {
        text-align: left;
        width: 100%;
    }

    .patrons{
        display: none;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
    }

    .title {
        font-size: 28px;
        font-weight: 200;
        text-align: left;

    }
}

@media screen and (max-width: 840px) {
    .wrapper {
        padding-top: 16px;
    }

    .content {
        margin-top: 8px;
    }
}