@import "styles/style";

.wrapper {
    position: relative;
}

.button {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    box-sizing: border-box;

    cursor: pointer;
}

.menu {
    top: 50px;
    right: 0px;
    position: absolute;
    border: 1px solid $gray-95;
    width: 280px;
    border-radius: 16px;
    background-color: $white;
    box-shadow: 0px 6px 12px -4px #0000003D;



    &__account {
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: $mainFont;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0px;
        overflow: hidden;

        &__container {
            display: flex;
            gap: 16px;
        }
    }

    &__header {
        display: flex;
        gap: 8px;
        padding: 16px;
        border-bottom: 1px solid $gray-95;
    }

    &__item {
        padding: 16px 24px;
        display: flex;
        gap: 16px;

        font-family: $mainFont;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0px;

        cursor: pointer;

        &:hover {
            background-color: $purple-99;
        }

    }

    &__logout {
        border-top: 1px solid $gray-95;
    }


}

.content {
    display: flex;
    flex-direction: column;
}

.name {
    font-family: $mainFont;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
}

.mail {
    font-family: $mainFont;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0em;
}

.delete {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    border-radius: 50px;

    cursor: pointer;

    &:hover {
        background-color: $purple-99;
    }
}

.img{
    width: 40px;
    height: 40px;
}