@import 'styles/style.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 480px;
    padding: 56px;
    box-sizing: border-box;
    width: 100%;
    gap: 24px;
    animation: ani 1s forwards;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
}

.link {
    color: #3300FF;
    text-decoration: none;
}

.description {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
}

.button {
    padding-top: 24px;
    display: flex;
    gap: 8px;
}

.saving {
    max-width: 488px;
}