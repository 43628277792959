@import 'styles/style.scss';

.menubar {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: flex-start;
    padding-left: 22px;
    min-height: 48px;
}

.item {
    font-family: $mainFont;
    font-size: 17px;
    height: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    box-sizing: border-box;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0 8px;

    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;

    color: $gray-50;
    border-bottom: 2px solid transparent;


    &:hover {
        box-sizing: border-box;

    }

    &_active {
        color: $gray-50;
        border-bottom: 2px solid black;
    }

    &_negative {
        color: $gray-50;
        font-weight: bold;
    }

    &_negative_active {
        color: $gray-50;
        font-weight: bold;
        border-bottom: 8px solid #a1a1e1;
        margin-bottom: -8px;
    }
}