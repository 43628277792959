@import 'styles/style.scss';

.wrapper {
    width: 100%;
    max-width: 432px;
    color: $gray-10;
    padding: 0 24px;
}

.title {
    font-size: 52px;
    line-height: 56px;
    font-family: $mainFont;
    padding-bottom: 64px;
}

.help {
    font-size: 17px;
    line-height: 24px;
    font-family: $mainFont;

    &__signup {
        color: $primary-1;
        cursor: pointer;
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 24px;
}

.cancel-request {
    background-color: #FFCEB2;
    padding: 8px;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 840px) {
    .wrapper {
        padding: 0 0;
    }
    
    .title {
        font-size: 40px;
        line-height: 44px;
        padding-bottom: 32px;
    }

    .buttons {
        padding-bottom: 16px;
    }

    .help {
        font-size: 15px;
    }
}