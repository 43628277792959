@import "styles/style";

.wrapper {
    position: relative;
}

.chips {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    white-space: nowrap;

    outline: 0;
    background: $white;
    height: 40px;
    border: 1.5px solid $gray-60;
    border-radius: 8px;
    padding: 0 8px;

    font-family: $mainFont;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0em;

    cursor: pointer;

    transition: background-color 0.2s ease, box-shadow 0.2s ease;

    &:hover {
        background-color: $purple-95;
    }
}

.menu {
    position: absolute;
    bottom: 52px;
    display: flex;
    width: 240px;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    background: $white;
    border-radius: 16px;
    box-shadow: 0px 6px 12px -4px rgba(0, 0, 0, 0.24);
    border: 1px solid $gray-95;
    z-index: 11;

    &__item {
        display: flex;
        height: 48px;
        padding: 0px 24px;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        cursor: pointer;

        &:hover {
            background-color: $purple-95;
        }
    }
}

@media screen and (max-width:$screen-mobile-mid) {}

@media screen and (max-width: 840px) {
    .chips {
        height: 32px;
        font-size: 13px;
    }
}