@import 'styles/style.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 480px;
    padding: 56px;
    box-sizing: border-box;
    width: 100%;
    gap: 24px;
    animation: ani 1s forwards;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
}

.amount {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 40px;
    font-weight: 200;
    line-height: 44px;
    letter-spacing: 0px;
}

.description {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    padding-top: 16px;
}

.button {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 24px;
}

.saving {
    max-width: 488px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    border-bottom: 1px solid $gray-10;
    color: $gray-10;

    font-family: $mainFont;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.status {
    padding: 4px 8px;

    &_green {
        background: #A6DAA8;
    }

    &_blue {
        background: #A3CAE7;
    }

    &_orange {
        background: #FFCEB2;
    }
}

.title {
    font-family: $mainFont;
    font-size: 27px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;

}

@media screen and (max-width: 840px) {

    .saving {
        display: none;
    }

    .wrapper {
        max-width: 100%;
        padding: 32px;
    }

    .button {
        padding-top: 0;
    }

    .description {
        font-size: 15px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .wrapper {
        padding: 32px 24px;
    }

    .button {
        padding-top: 0;
    }
}