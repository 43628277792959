@import 'styles/style.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 480px;
    padding: 56px;
    box-sizing: border-box;
    width: 100%;
    gap: 24px;
    animation: ani 1s forwards;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 34px;
    font-weight: 400;
    line-height: 40px;
}

.amount {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 40px;
    font-weight: 200;
    line-height: 44px;
    letter-spacing: 0px;
}

.description {
    color: $gray-10;
    font-family: $mainFont;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
}

.button {
    padding-top: 24px;
    display: flex;
    gap: 8px;
}

.saving {
    max-width: 488px;
}

@media screen and (max-width: 840px) {
    .wrapper {
        max-width: 100%;
        padding: 32px 24px;
        gap: 16px;
    }

    .saving {
        display: flex;
        max-width: 312px;
    }

    .title {
        font-size: 27px;
        line-height: 32px;
    }

    .amount {
        font-size: 32px;
        line-height: 36px;
    }

    .description {
        font-size: 17px;
        line-height: 20px;
    }

}

@media screen and (max-width: 400px) {
    .wrapper {
        max-width: 100%;
        padding: 32px 24px;
    }


}