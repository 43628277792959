@import 'styles/style.scss';

.wrapper {
    display: flex;
    align-items: center;
    height: 52px;
    gap: 16px;
    border-bottom: 1px solid $gray-95;
    width: 100%;
    color: $gray-60;
    font-size: 15px;
    line-height: 20px;
    font-family: $mainFont;
    box-sizing: border-box;
    text-align: start;
    padding: 16px 0;
}

.item {
    padding-right: 10px;
    color: $gray-60;
    box-sizing: border-box;
}

@media screen and (max-width: 1170px) {
    .wrapper {
        gap: 8px;
    }
}