@import '../../styles/style.scss';

.wrapper {
    height: 49px;
    border-radius: 24px;
    border: 1px solid #0000003D;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: transparent;
}

.field {
    width: 100%;
    // height: 48px;
    border: 0;
    outline: 0;
    border-radius: 50px 0 0 50px;
    padding: 0 4px 0 16px;
    box-sizing: border-box;
    background-color: transparent;
}

@media screen and (max-width: 840px) {
    .wrapper {
        height: 41px;
    }
}